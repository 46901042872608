import { api } from "@/base/utils/request";

export const getTemplateList = (data) => {
  return api({
    url: '/admin/venue/approvalTmpl/index',
    method: 'post',
    data
  })
}

export const updateTemplateStatus = (data) => {
  return api({
    url: '/admin/venue/approvalTmpl/updateStatus',
    method: 'post',
    data
  })
}

export const moveTemplateToDeleted = (ids) => {
  return api({
    url: '/admin/venue/approvalTmpl/softDelete',
    method: 'post',
    data: {
      ids
    }
  })
}

export const deleteTemplate = (ids) => {
  return api({
    url: '/admin/venue/approvalTmpl/del',
    method: 'post',
    data: {
      ids
    }
  })
}

export const moveTemplateFromDeleted = (ids => {
  return api({
    url: '/admin/venue/approvalTmpl/putBack',
    method: 'post',
    data: {
      ids
    }
  })
})
